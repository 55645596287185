<template>
  <div
    class="driverPerformance"
    v-bind:class="{ 'is-active': showVehicleModal }"
    style="z-index: 1000 !important;"
  >
    <div class="has-background-white is-flex is-flex-direction-column">
      <header class="">
        <p class="">
            <section class="hero is-primary is-small">
              <div class="hero-body">
                <div class="is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
                  <h4 class="is-3 has-text-weight-bold">
                    Vehicles
                  </h4>
                  <button
                    class="button is-success is-inverted is-outlined is-rounded"
                    style="left: 0 ; right: 0; bottom: 0;"
                    @click.prevent="
                      (e) => {
                        e.target.classList.toggle('is-loading')
                        saveFleetSettings(e)
                      }
                    "
                  >
                      Save
                      <!-- {{ $t('buttons.save') }} -->
                  </button>
                </div>
              </div>
            </section>
            <!-- {{ $t('comp.vehiclesModal.p') }} -->
        </p>
      </header>
      <div class="is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
        <div class="tabs is-toggle is-centered is-rounded is-small is-primary pt-5">
          <ul>
            <li :class="{ 'is-active': activeTab === 1 }">
              <a @click="activeTab=1">
                <span>All</span>
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 2 }">
              <a @click="activeTab=2">
                <span>Per Zone</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="py-1">
          <button
            class="button is-small is-rounded is-outlined"
            @click="collapseVehicles = !collapseVehicles"
          >
            Toggle Trucks
          </button>
        </div>
      </div>

      <section class="scrolling" style="overflow-y: scroll;height: 65vh;">
        <md-list class="md-double-line" v-if="activeTab === 1">
          <div v-for="(vehicle, index) in selectedVehicles" v-bind:key="index">
            <VehicleSettingCell
              :index="vehicle.truckIndex"
              :vehicle="vehicle"
              :zones="zones"
              :collapseVehicles="collapseVehicles"
            />
          </div>
        </md-list>
        <md-list class="md-double-line" v-if="activeTab === 2">
          <div v-for="(zone, index) in selectedVehicles" v-bind:key="index">
            <!-- <nav class="level" style="padding: 3px">
              <div class="level-left">
                <div class="level-item pl-3">
                  <strong>
                    Zone: {{zone.zone}}
                  </strong>
                </div>
              </div>
            </nav> -->
            <div class="card">
              <header class="card-header has-background-dark">
                <p class="card-header-title has-text-white-bis">
                  {{zone.zone}}
                </p>
              </header>
              <div class="card-content">
                <div v-if="zone.trucks.length > 0">
                  <div v-for="(vehicle, Tindex) in zone.trucks" v-bind:key="Tindex">
                    <VehicleSettingCell
                      :index="vehicle.truckIndex"
                      :vehicle="vehicle"
                      :zones="zones"
                      :collapseVehicles="collapseVehicles"
                    />
                  </div>
                </div>
                <div v-else>
                  <div>
                    No Trucks Assigned on this Zone
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-list>
      </section>
      <!-- <footer class="footer">
        <button
          class="button is-success"
          style="left: 0 ; right: 0; bottom: 0; width: 100%"
          @click="saveFleetSettings"
        >
            Save
        </button>
      </footer> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import VehicleSettingCell from '@/components/VehicleSettingCell.vue'
import { fleetSettings } from '../store/fireStore'
export default {
  components: {
    VehicleSettingCell
  },
  data () {
    return {
      activeTab: 1,
      collapseVehicles: false
    }
  },
  props: {
    showVehicleModal: {
      type: Boolean
    },
    zones: {
      type: Array
    },
    regions: {
      type: Array
    },
    vehicleObj: {
      type: Object
    }
  },
  computed: {
    ...mapState([
      'region'
    ]),
    selectedVehicles () {
      if (this.activeTab === 1) {
        return this.$store.state.fleetSettings[this.region]
      } else {
        const zones = this.$store.state.regionZones.filter(zone => zone.region === this.region).map(zone => zone.zone)
        const formattedTrucks = zones.map(zone => {
          const selectedTruckPerRegion = this.$store.state.fleetSettings[this.region].filter(truck => truck.zone === zone)
          return { zone, trucks: selectedTruckPerRegion }
        })
        const unAssignedTrucks = this.$store.state.fleetSettings[this.region].filter(truck => truck.zone === '' || !truck.zone || !zones.includes(truck.zone))
        formattedTrucks.push({ zone: 'Unassigned Trucks', trucks: unAssignedTrucks })
        return formattedTrucks
      }
    }
  },
  methods: {
    async saveFleetSettings (e) {
      await fleetSettings.doc(`${this.region.trim()}`).set({ fleet: this.$store.state.fleetSettings[this.region] })
        .then((doc) => {
          e.target.classList.toggle('is-loading')
        })
    }
  }
}
</script>
