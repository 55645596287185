<template>
  <div
    class="notification"
    style="border: 1px solid #ddd;  margin: 10px"
    v-bind:style="{opacity: isTruckLocked ? 0.5 : 1}"
  >
    <progress
      class="progress is-primary is-small"
      style="position: absolute; top: 0; left: 0 ; right: 0; border-radius: 0"
      :value="vehicle.capacity"
      :max="40"
    >
        Load
        <!-- {{ $t('comp.vehicleSettingCell.load') }} -->
    </progress>

    <nav class="level" style="padding: 3px">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <strong>
            {{vehicle.name}}
          </strong>
        </div>
      </div>
    </nav>

    <div class="is-flex is-flex-wrap-wrap" style="padding: 3px" v-if="!collapseVehicles">
      <div class="is-flex is-flex-wrap-wrap">
        <div class="is-flex is-flex-direction-column p-2">
          <span style="text-align:start;">
              Starting Time
          </span>
          <div class="select is-rounded is-small">
            <select
              :disabled="isTruckLocked"
              style="width:120px;"
              @change="(e) => updateStartEndTime(e, 'START')"
            >
              <option>{{ this.$moment(vehicle.shift_start).utc().format("HH:mm") }}</option>
              <option
                v-for="(option, index) in timeOptions"
                :key="index"
                :value="option"
              >{{ option }}</option>
            </select>
          </div>
        </div>
        <div class="is-flex is-flex-direction-column p-2">
          <span style="text-align:start;">
            Starting Location
            <!-- {{ $t('comp.vehicleSettingCell.labels.endLoc') }} -->
          </span>
          <input
            :disabled="isTruckLocked"
            class="input"
            type="text"
            v-model="vehicle.start_location.name"
            @change="(e) => addressChange(e,'START')"
          />
        </div>
      </div>

      <div class="is-flex is-flex-wrap-wrap">
        <div class="is-flex is-flex-direction-column p-2">
          <span style="text-align:start;">
              Ending Time
          </span>
          <div class="select is-rounded is-small">
            <select
              :disabled="isTruckLocked"
              style="width:120px;"
              @change="(e) => updateStartEndTime(e, 'END')"
            >
              <option>{{ this.$moment(vehicle.shift_end).utc().format("HH:mm") }}</option>
              <option
                v-for="(option, index) in timeOptions"
                :key="index"
                :value="option"
              >{{ option }}</option>
            </select>
          </div>
        </div>
        <div class="is-flex is-flex-direction-column p-2">
           <span style="text-align:start;">
            Ending Location
            <!-- {{ $t('comp.vehicleSettingCell.labels.endLoc') }} -->
          </span>
          <input
            :disabled="isTruckLocked"
            class="input"
            type="text"
            v-model="vehicle.end_location.name"
            @change="(e) => addressChange(e,'END')"
          />
        </div>
      </div>

      <div class="is-flex is-flex-wrap-wrap">
        <div class="is-flex is-flex-direction-column p-2 is-justify-content-space-between">
          <span style="text-align:start;">
            Zone
          </span>
          <div class="select is-rounded is-small">
            <select
              :disabled="isTruckLocked"
              @change="zoneChange"
              style="width:120px;"
            >
              <option
                value=""
                :selected="vehicle.zone === ''"
              >No Zone</option>
              <option
                v-for="(option, index) in zones"
                :key="index"
                :value="option.zone"
                :selected="option.zone === vehicle.zone"
              >{{ option.zone }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="p-2" style="width: 100%">
        <div class="is-flex is-flex-direction-column is-justify-content-space-between">
          <span class="p-1" style="text-align:start;">
              Capacity: {{vehicle.capacity}}
          </span>
          <input
            class="slider"
            :disabled="isTruckLocked"
            type="range"
            min="0"
            max="40"
            id="myRange"
            v-model="vehicle.capacity"
            @change="(e) => capacityChange(e)"
          />
        </div>
      </div>
    </div>
    <span
      v-if="isTruckLocked"
      class="icon has-text-danger"
      style="position: absolute; right: 3px; top: 3px"
    >
      <i class="fas fa-lock"></i>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { timeOptions } from '@/exports/time'

export default {
  data () {
    return {
      timeOptions: timeOptions
    }
  },
  props: {
    isTruckLocked: Boolean,
    vehicle: Object,
    zones: Array,
    index: Number,
    collapseVehicles: Boolean,
    countPieces: Boolean
  },
  methods: {
    capacityChange (e) {
      this.$store.dispatch('setTruckFleetSetting', {
        value: parseInt(e.target.value),
        key: 'capacity',
        index: this.index,
        region: this.$store.state.region
      })
    },
    updateStartEndTime (e, hub) {
      const value = e.target.value
      const date = this.$moment()
        .utc()
        .set('hour', parseInt(value.split(':')[0]))
        .set('minute', parseInt(value.split(':')[1]))
      let hubStartEnd
      if (hub === 'START') {
        hubStartEnd = 'shift_start'
      } else {
        hubStartEnd = 'shift_end'
      }

      const datetime = new Date(date.toISOString()).toUTCString()
      this.$store.dispatch('setTruckFleetSetting', {
        value: new Date(datetime).getTime(),
        key: hubStartEnd,
        index: this.index,
        region: this.$store.state.region
      })
    },
    addressChange (e, startEnd) {
      let hubStartEnd
      if (startEnd === 'START') {
        hubStartEnd = 'start_location'
      } else {
        hubStartEnd = 'end_location'
      }
      this.$store.dispatch('setTruckFleetSetting', {
        value: e.target.value,
        key: hubStartEnd,
        index: this.index,
        region: this.$store.state.region,
        innerKey: 'name'
      })
    },
    zoneChange (e) {
      console.log({
        value: e.target.value,
        key: 'zone',
        index: this.index,
        region: this.$store.state.region
      })
      this.$store.dispatch('setTruckFleetSetting', {
        value: e.target.value,
        key: 'zone',
        index: this.index,
        region: this.$store.state.region
      })
      console.log('STATE: ', this.$store.state)
    }
  },
  computed: {
    ...mapState(['deliveryData']),
    load () {
      return 5
    }
  }
}
</script>

<style lang="scss" scoped>
.column {
  margin: 0.5px;
  overflow: hidden;
  height: 44px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  vertical-align: middle;
  text-align: right !important;
}
#modal {
  z-index: 1000;
}
.md-field {
  max-width: 400px;
}
#dropdown {
  position: absolute;
  left: 100px;
  bottom: 2px;
}
.test {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.7;
  z-index: 2000 !important;
}
option,
select {
  border: none;
}
.ellipsis {
  padding: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.slidecontainer {
  width: 100%;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}
</style>
